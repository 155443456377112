import React from "react";
import "./App.css";
import Home from "./Home";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxm2bsu24hztc-Hhs7MbwlAuEmhKrTM2Y",
  authDomain: "tweetclipz.firebaseapp.com",
  projectId: "tweetclipz",
  storageBucket: "tweetclipz.appspot.com",
  messagingSenderId: "559354213201",
  appId: "1:559354213201:web:5e10bd1e3c228a106bd970",
  measurementId: "G-F5SVK82HZG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
