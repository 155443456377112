import React from "react";

const Header = () => {
  return (
    <div className="header">
      <h2 className="header_title">TweetClipz</h2>
    </div>
  );
};

export default Header;
