import React from "react";

interface IProps {
  value: string;
  placeHolder: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputBox: React.FC<IProps> = ({ value, placeHolder, handleChange }) => {
  return (
    <div className="input_box">
      <input
        type="text"
        placeholder={placeHolder}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default InputBox;
