import React, { useState } from "react";

import SubmitButton from "./components/SubmitButton";
import InputBox from "./components/InputBox";
import Footer from "./components/Footer";
import Header from "./components/Header";
import YouTubeCard from "./components/YoutubeCard";
import TweetCard from "./components/TweetCard";

const sampleYoutubeData = [
  {
    id: 1,
    title: "Create A Website For FREE in 2023",
    imageSrc: "https://img.youtube.com/vi/kdOCHkgeLNc/sddefault.jpg",
    link: "https://www.youtube.com/watch?v=kdOCHkgeLNc",
  },
  {
    id: 2,
    title: "Surprising Habits to Get Millions",
    imageSrc: "https://img.youtube.com/vi/vBqOMLd1ZQk/sddefault.jpg",
    link: "https://www.youtube.com/watch?v=vBqOMLd1ZQk",
  },
  {
    id: 3,
    title: "How to Grow an Audience",
    imageSrc: "https://img.youtube.com/vi/7ITff1fIbSc/sddefault.jpg",
    link: "https://www.youtube.com/watch?v=7ITff1fIbSc",
  },
  {
    id: 4,
    title: "How to Make Thumbnails",
    imageSrc: "https://img.youtube.com/vi/iaA8l-noIdM/sddefault.jpg",
    link: "https://www.youtube.com/watch?v=iaA8l-noIdM",
  },
  {
    id: 5,
    title: "How I Learned to Code in 4 Months",
    imageSrc: "https://img.youtube.com/vi/WR1ydijTx5E/sddefault.jpg",
    link: "https://www.youtube.com/watch?v=WR1ydijTx5E",
  },
  {
    id: 6,
    title: "How to Create Video Ads",
    imageSrc: "https://img.youtube.com/vi/REWCF2S8tls/sddefault.jpg",
    link: "https://www.youtube.com/watch?v=REWCF2S8tls",
  },
];

const baseUrl =
  "https://42b0-3-144-83-126.ngrok-free.app";

const Home = () => {
  const [value, setValue] = useState("");
  const [tweets, setTweets] = useState("");
  const [youtubeTitle, setYoutubeTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setTweets("");
    setValue(e.target.value);
  };

  const handleSubmit = async () => {
    setTweets("");
    setError("");
    // regex to check if suybmitted url is valid youtube url
    const youtubeUrlRegex =
    // eslint-disable-next-line
      /^(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)\&?/;
    // check if url is valid youtube url
    if (!youtubeUrlRegex.test(value) || !value.length) {
      setError("Please enter a valid youtube URL");
      return;
    }

    const getTweetsUrl = baseUrl + "/tweets";
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: value,
      }),
    };
    const response = await fetch(getTweetsUrl, requestOptions);
    if (response.status === 200) {
      const { tweets, video_title } = await response.json();
      setTweets(tweets);
      setYoutubeTitle(video_title);
      console.log(tweets);
      setLoading(false);
    } else if (response.status === 400) {
      const { message } = await response.json();
      setLoading(false);
      setError(message);
    } else {
      setLoading(false);
      setError("An error occurred. Please try again later.");
      throw new Error("Error while fetching Tweets");
    }
  };

  const handleCardSelect = (cardLink: string) => {
    setValue(cardLink);
  };

  return (
    <div className="home">
      <Header />
      <div className="content">
        <h2>Create Viral Tweet Content With YouTube Videos</h2>
      </div>
      <div className="input_container">
        <InputBox
          placeHolder="Youtube Video Link"
          value={value}
          handleChange={handleChange}
        />
        <SubmitButton
          buttonText="Generate Tweet"
          handleClick={handleSubmit}
          disableStatus={loading}
        />
      </div>
      {loading && (
        <p
          style={{
            textAlign: "center",
            color: "#194aa5",
          }}
        >
        Please wait while the tweet is being generated...! It takes up to 3 minutes to generate tweets.        
        </p>
      )}
      {error && (
        <p style={{ color: "red", textAlign: "center", marginRight: "170px" }}>
          {error}
        </p>
      )}
      {tweets && !error && (
        <TweetCard tweetContent={tweets} videoTitle={youtubeTitle} />
      )}
      <div className='youtube_container'>
        <div className="youtube_cards">
          {sampleYoutubeData.map(({ id, title, imageSrc, link }) => {
            return (
              <YouTubeCard
                key={id}
                title={title}
                imageSrc={imageSrc}
                handleCardClick={() => handleCardSelect(link)}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
