import React, { useRef, useState } from "react";

interface IProps {
  tweetContent: string;
  videoTitle: string;
}

const TweetCard: React.FC<IProps> = ({ tweetContent, videoTitle }) => {

  const tweetRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line 
  const [showCopiedText, setShowCopiedText] = useState(false);

  const copyTweet = () => {
    // copy tweetContent to clipboard
    if(tweetRef.current) {
      navigator.clipboard.writeText(tweetRef.current.innerText);
    }
    setShowCopiedText(true)
    setTimeout(() => {
      setShowCopiedText(false);
    }, 1000)
  
  }
  return (
    <div className="tweet_card">
      <h3 style={{ marginLeft: "50px", marginTop: "50px" }}>
        Tweet Generated For The Video: {videoTitle}
      </h3>
      <div className="tweet_container">
        <div ref={tweetRef} className="tweet_content">
          {tweetContent.split("\n").map((item, idx) => {
            return (
              <span key={idx}>
                {item}
                <br />
              </span>
            );
          })}
        </div>
        <div className="tweet_copy_btn">
          <button className="copy_btn" onClick={copyTweet}>Copy</button>
        </div>
      </div>
    </div>
  );
};

export default TweetCard;
