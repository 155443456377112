import React from "react";

interface IProps {
  title: string;
  imageSrc: string;
  handleCardClick: () => void;
}

const YouTubeCard: React.FC<IProps> = ({
  title,
  imageSrc,
  handleCardClick,
}) => {
  return (
    <div className="yt-card" onClick={handleCardClick}>
      <img style={{ width: "310px" }} src={imageSrc} alt="Youtube" />
      <h3>{title}</h3>
    </div>
  );
};
export default YouTubeCard;
