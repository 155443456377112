import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <p className="footer_content">© 2023 TweetClipz | All rights reserved</p>
    </div>
  );
};

export default Footer;
